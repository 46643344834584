<template>
  <div class="update">
    <el-form :model="form"
             :rules="formRules"
             ref="unitForm"
             @keyup.enter="save"
             @submit.prevent>
      <el-form-item prop="name">
        <el-input v-model.trim="form.name" size="mini" placeholder="单位"></el-input>
      </el-form-item>
    </el-form>
    <el-button type="danger" size="mini" @click="save" :loading="isLoading">保存</el-button>
  </div>
</template>

<script>
export default {
  props: {
    layerInfo: Object
  },

  data() {
    return {
      form: {
        id: null,
        name: ""
      },
      formRules: {
        name: [{required: true, message: "请填写单位", trigger: "blur"}]
      },

      isLoading: false
    }
  },

  mounted() {
    this.form = {
      id: this.layerInfo.id,
      name: this.layerInfo.name
    }
  },

  methods: {
    async save() {
      this.$refs["unitForm"].validate(async valid => {
        if (valid) {
          this.isLoading = true
          const sendData = {
            id: this.form.id,
            companyName: this.form.name
          }
          const res = await this.$api.Base.Unit.update(sendData).catch(() => {})
          this.isLoading = false
          if (res?.code === 200) {
            this.$message.success("修改成功")
            this.$emit("handle")
            this.$emit("close")
          } else {
            this.$message.error(res?.msg || "修改失败")
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "update";
</style>
